import req from "./request";

export default {
  index: async () => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;

      let url = `projectings?building_id=${building.id}`;
      const { data } = await req.get(url, true);
      return data;
    } catch (error) {
      throw error;
    }
  },
  show: async (id) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;

      const { data } = await req.get(
        `projectings/${id}?building_id=${building.id}`,
        true
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  store: async (data) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      const response = await req.post(
        `projectings?building_id=${building.id}`,
        data,
        true
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateProjecting: async (id, data) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      let url = `projectings/${id}?building_id=${building.id}`;
      const response = await req.put(url, data, true);
      return response;
    } catch (error) {
      throw error;
    }
  },
  update: async (id, data) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      let url = `projectings/documents/${id}?building_id=${building.id}&projecting_id=${projectingId}`;
      if (projectingSubdirectoryId)
        url += `&projecting_subdirectory_id=${projectingSubdirectoryId}`;

      const response = await req.put(url, data, true);
      return response;
    } catch (error) {
      throw error;
    }
  },
  document: async (id, projectingId, projectingSubdirectoryId = null) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      let url = `projectings/documents/${id}?building_id=${building.id}&projecting_id=${projectingId}`;
      if (projectingSubdirectoryId)
        url += `&projecting_subdirectory_id=${projectingSubdirectoryId}`;
      return await req.get(url, true);
    } catch (e) {
      throw e;
    }
  },
};
