import req from "./request";

export default {
  store: async (data) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      let mediaData = new FormData();
      mediaData.append("document", data);

      return await req.postH("documents", mediaData, true, {
        "Content-Type": "application/x-www-form-urlencoded",
      });
    } catch (error) {
      throw error;
    }
  },
  _delete: async (id) => {
    try {
      return await req.delete(`documents/${id}`, true);
    } catch (error) {
      throw error;
    }
  },
  show: async (id) => {
    try {
      const { data } = await req.get(`documents/${id}`, true);
      const link = document.createElement("a");
      link.href = data.path;
      if(!navigator.platform.match(/iPhone|iPod|iPad/)){
        link.target = "_blank";
      }
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        link.remove();
      }, 200);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
};
