import req from "./request";

export default {
  index: async (type, projectingId, projectingSubdirectoryId = null) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      let url = `download?building_id=${building.id}&type=${type}&projecting_id=${projectingId}`;
      if (projectingSubdirectoryId)
        url += `&projecting_subdirectory_id=${projectingSubdirectoryId}`;

      return await req.get(url, true);
    } catch (error) {
      throw error;
    }
  },
  single: async (type, id, projectingId, projectingSubdirectoryId = null) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      let url = `download/${id}?building_id=${building.id}&type=${type}&projecting_id=${projectingId}`;
      if (projectingSubdirectoryId)
        url += `&projecting_subdirectory_id=${projectingSubdirectoryId}`;

      return await req.get(url, true);
    } catch (error) {
      throw error;
    }
  },
  downloadFromCode: async (code) => {
    try {
      const { data } = await req.get(`download/file/${code}`, true);
      const link = document.createElement("a");
      link.href = data.url;
      if(!navigator.platform.match(/iPhone|iPod|iPad/)){
        link.target = "_blank";
      }
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        link.remove();
      }, 900);
    } catch (error) {
      throw error;
    }
  },
  _delete: async (id) => {
    try {
      return await req.delete(`downloads/${id}`, true);
    } catch (error) {
      throw error;
    }
  },
};
