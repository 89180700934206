import bus from "../plugins/bus";

export default (error, router) => {
  console.debug("PERMISSION DENIED HANDLER FIRE");
  if (error.response && error.response.status == 403) {
    router.push({ name: "Dashboard" });
    bus.$emit("snackbar", {
      text: error.response.data.message,
      color: "error",
    });
    return false;
  }
  return true;
};
