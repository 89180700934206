<template>
  <div class="admin-container" v-if="data">
    <v-row class="justify-space-between">
      <v-col cols="12" md="4">
        <h1 class="col-secondary-1">Stavební deník</h1>
      </v-col>
      <v-col
        align-self="center"
        v-if="user.role != 'END_USER' && user.role != 'COMPANY_CUSTOMER'"
      >
        <v-row class="justify-md-end">
          <!-- <v-col align-self="center" md="2" class="pa-0 flex-0 mt-8 mt-md-0"> -->
          <v-col align-self="center" md="2" class="pa-0 flex-md-0 mt-4 mt-md-0">
            <v-btn
              x-small
              tile
              color="secondary"
              height="32"
              class="mobile-width-send-message"
              @click="$router.push({ name: 'ConstructionDiary-new' })"
              ><v-icon small color="primary">mdi-plus</v-icon> Přidat
              záznam</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <h1 v-if="!data.length" class="mt-10 mb-10 text-center">
      Žádné stavební deníky nalezeny ..
    </h1>

    <input
      style="display: none"
      ref="documentFile"
      type="file"
      class="input--file"
      @input="handleInputFile($event.target.files[0])"
    />

    <input
      style="display: none"
      ref="photoFile"
      type="file"
      accept="image/*"
      capture="camera"
      class="input--file"
      @input="handleInputFile($event.target.files[0])"
    />

    <v-row style="margin-top: 80px">
      <v-col cols="12">
        <v-expansion-panels tile>
          <v-expansion-panel v-for="(item, idx) in data" :key="item.id">
            <v-expansion-panel-header style="height: 64px" class="bg-primary-2">
              <strong> {{ item.formatted_time }}</strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div>
                <table class="table-construction-diary">
                  <tr
                    v-for="constructionDiary in item.construction_diaries"
                    :key="constructionDiary.id"
                    @click="
                      $router.push({
                        name: 'ConstructionDiary-detail',
                        params: { id: constructionDiary.id },
                      })
                    "
                    style="cursor: pointer"
                  >
                    <td class="pr-6">
                      <span class="col-secodary-2" style="font-size: 16px">{{
                        constructionDiary.name
                      }}</span>
                      <span
                        class="d-block col-secondary-3"
                        style="font-size: 14px"
                        >{{ constructionDiary.date_of_record }}</span
                      >
                    </td>
                    <td class="text-right">
                      <v-btn
                        tile
                        small
                        color="secondary"
                        class="construction-diary-btn"
                        @click.stop="
                          $router.push({
                            name: 'Timeline-detail',
                            params: {
                              id: constructionDiary.timeline.id,
                            },
                          })
                        "
                        >{{ constructionDiary.timeline.name }}</v-btn
                      >
                      <v-btn
                        tile
                        small
                        color="secondary"
                        class="construction-diary-btn"
                        @click.stop="
                          handleClick('documentFile', constructionDiary.id)
                        "
                        v-if="
                          ['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)
                        "
                        >Vložit soubor</v-btn
                      >
                      <v-btn
                        tile
                        small
                        color="secondary"
                        class="construction-diary-btn"
                        v-if="
                          ['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)
                        "
                        @click.stop="
                          handleClick('photoFile', constructionDiary.id)
                        "
                        >Pořídit foto</v-btn
                      >
                      <v-btn
                        tile
                        small
                        text
                        class="construction-diary-btn"
                        @click.stop="download(constructionDiary.id)"
                        >Stáhnout</v-btn
                      >
                      <v-btn
                        small
                        color="primary"
                        style="z-index: 999"
                        class="construction-diary-btn"
                        tile
                        @click.stop="toMessages(constructionDiary.id)"
                      >
                        <div class="d-flex flex-align-center">
                          <img
                            :src="require('@/assets/poslat_zpravu.svg')"
                            class="icon-wrapp mr-2"
                            style="width: 15px; height: 15px"
                          />
                          Poslat zprávu
                        </div>
                      </v-btn>
                    </td>
                  </tr>
                </table>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import authHandler from '../api/authHandler';
import permissionDeniedHandler from '../api/permissionDeniedHandler';
import bus from '../plugins/bus';
import projectingService from '../api/projectingService';
import downloadService from '../api/downloadService';
import errorHandler from '../api/errorHandler';
import constructionDiaryService from '../api/constructionDiaryService';
import documentService from '../api/documentService';

export default {
  data() {
    return {
      data: null,
      focusedId: null,
    };
  },
  async created() {
    try {
      //   await this.$store.dispatch("getCurrentUser");
      if (this.getSelectedBuilding() != null) {
        await this.index();
      } else {
        this.$router.push({ name: 'Dashboard' });
        bus.$emit('snackbar', {
          text: 'Není vybrána žádná stavba.',
          color: 'error',
        });
      }
    } catch (error) {
      if (!authHandler(error)) this.$router.push({ name: 'Login' });
      permissionDeniedHandler(error, this.$router);
    }
  },
  methods: {
    async handleInputFile(f) {
      try {
        bus.$emit('processing');
        const { data: document } = await documentService.store(f);
        console.debug(document);
        await constructionDiaryService.storeDocuments(
          {
            documents: [
              {
                document_id: document.id,
                document_name: document.client_name,
                mime: document.mime,
              },
            ],
          },
          this.focusedId
        );
        bus.$emit('processing', false);
        bus.$emit('snackbar', {
          text: `Podařilo se nahrát dokumenty`,
          color: 'info',
        });
      } catch (error) {
        bus.$emit('processing', false);
        errorHandler(error);
      }
      this.focusedId = null;
    },
    handleClick(ref, id) {
      this.focusedId = id;
      this.$refs[ref].click();
    },
    async download(id) {
      try {
        const { data } = await constructionDiaryService.download(id);
        await downloadService.downloadFromCode(data.code);
        console.debug(data);
      } catch (error) {
        console.error(error);
        errorHandler(error);
      }
    },
    toMessages(constructionDiaryId) {
      if (['END_USER', 'COMPANY_CUSTOMER'].includes(this.user.role)) {
        this.$router.push({
          name: 'MessagesDetail',
          params: { id: this.getSelectedBuilding().project_manager.id },
          query: {
            construction_diary: constructionDiaryId,
          },
        });
      }
      if (['PROJECT_MANAGER', 'SUPERUSER'].includes(this.user.role)) {
        this.$router.push({
          name: 'MessagesDetail',
          params: { id: this.getSelectedBuilding().investor.id },
          query: {
            construction_diary: constructionDiaryId,
          },
        });
      }
    },
    // toMessages(construction)(constructionDiaryId, projectManagerId) {
    //   this.$router.push({
    //     name: "MessagesDetail",
    //     params: { id: projectManagerId },
    //     query: {
    //       construction_diary: constructionDiaryId,
    //     },
    //   });
    // },
    getSelectedBuilding() {
      const building = JSON.parse(localStorage.getItem('building')) || null;
      return building;
    },
    async store() {
      try {
        bus.$emit('processing');
        await projectingService.store({
          ...this.currentProjectState,
        });
        this.data.current_project_state.push({
          ...this.currentProjectState,
          id: Date.now(),
        });
        this.currentProjectState = {
          status: null,
          section: 'CURRENT_PROJECT_STATE',
          subject: null,
          note: null,
          date: null,
        };
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit('snackbar', {
            text: error.response.data.error.message,
            color: 'error',
          });
        }
      }
    },

    async index() {
      try {
        bus.$emit('processing');
        const data = await constructionDiaryService.index();
        this.data = data;
        console.log(data);
        bus.$emit('processing', false);
        await bus.$emit('refresh_sections');
      } catch (error) {
        console.error(error);
        bus.$emit('processing', false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit('snackbar', {
            text: error.response.data.error.message,
            color: 'error',
          });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    stahnout() {
      return {
        backgroundImage: `url(${require('@/assets/stahnout.svg')} !important`,
      };
    },
  },
};
</script>

<style></style>
